<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col
          class="pt-0"
          v-for="(item, index) in data"
          :key="index"
          :cols="item.col"
          :id="item.col_id"
        >
          <v-row>
            <v-col cols="12" sm="10">
              <label class="text-xs text-typo font-weight-bolder ms-1">{{
                item.label
              }}</label>
            </v-col>

            <v-col
              class="px-0"
              cols="12"
              sm="2"
              v-if="item.type == 'file' && item.file != ''"
            >
              <router-link
                target="_blank"
                class="text-decoration-none"
                :to="$baseURL + 'storage/customers/' + item.file"
              >
                <v-btn
                  color="#fff"
                  class="text-dark font-weight-bolder py-6 px-5 shadow-0"
                  small
                  simple
                >
                  <v-icon size="18" class="rotate-180"
                    >fas fa-file-pdf text-lg me-1</v-icon
                  >
                  {{ $t("ticket.file") }}
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
          <!-- :required="item.required" -->

          <v-checkbox
            v-if="item.type == 'checkbox'"
            :rules="item.rules"
            class="mt-0"
            v-model="item.value"
            :color="item.color"
            :label="item.label_input"
            :value="item.value_text"
            :error-messages="item.error"
          ></v-checkbox>

          <v-select
            v-else-if="item.type == 'select'"
            v-model="item.value"
            :items="item.items"
            :item-text="item.title_select ? item.title_select : 'name'"
            item-value="id"
            :label="$t('general.choose')"
            :error-messages="item.error"
            dense
            :rules="item.rules"
            :multiple="item.type_select == 'multiple' ? true : false"
            outlined
          ></v-select>

          <v-textarea
            v-else-if="item.type == 'textarea'"
            :label="item.label"
            auto-grow
            outlined
            :rows="item.row"
            row-height="15"
            :error-messages="item.error"
            :rules="item.rules"
            v-model="item.value"
          ></v-textarea>

          <div v-else-if="item.type == 'file'">
            <v-file-input
              class="mb-2"
              v-model="item.value"
              :multiple="item.type_file == 'multiple' ? true : false"
              :rules="item.rules"
              :accept="item.accept"
              show-size
              :error-messages="item.error"
              counter
              chips
            ></v-file-input>
          </div>

          <v-text-field
            v-else
            :rules="item.rules"
            :min="item.min"
            :type="item.type"
            :error-messages="item.error"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="item.placeholder"
            class="font-size-input placeholder-lighter text-light-input"
            v-model="item.value"
          >
          </v-text-field>

          {{ item.data }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 text-right">
          <!-- if btn false will be appered in form -->
          <v-btn
            v-if="!btn"
            :ripple="false"
            class="btn-primary py-5 px-6 mb-0"
            text
            :loading="loader"
            color="white"
            @click="save()"
          >
            <span slot="loader">
              <v-progress-circular
                style="width: 20px; height: 20px"
                indeterminate
                color="white"
              ></v-progress-circular>
            </span>
            {{ $t("form.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
export default {
  props: { data: {}, btn: Boolean, loader: Boolean },
  name: "Form",
  data() {
    return {
      // btn:true,
      valid: true,
      validation: false,
      loading: false,
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    set_selectbox_data(data, index) {
      this.data[index].items = data;
    },
    save: function () {
      if (this.validate()) {
        this.validation = true;
      } else {
        this.validation = false;
      }
      this.$emit("save", this.validation);
    },
  },
  mounted() {
    this.resetValidation();
    // console.log(this.loader);
    // this.loading = this.loader
    // console.log(this.btn)
  },
};
</script>
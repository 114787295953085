<template>
  <div>
    <v-container fluid>
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <h5 class="text-h3 text-typo font-weight-bold ms-4 mb-3">
                {{ $t("cycle.Edit Cycle") }}
              </h5>
            </v-col>
          </v-row>
          <v-row class="position-relative">
            <Loading v-if="loading"></Loading>
            <v-col lg="6" cols="6" class="mx-auto mb-6">
              <v-card class="card-shadow mt-6" id="basic">
                <Header :name="$t('cycle.General Information')"></Header>
                <div class="px-6 pb-6">
                  <Form
                    :loader="loader_form"
                    :data="data_style"
                    @save="edit_cycle"
                  ></Form>
                </div>
              </v-card>
              <button
                v-if="show_btn"
                class="py-5 px-6 mb-0 ms-auto"
                @click="save_change()"
              >
                <v-icon class="custome-btn btn-primary"
                  >mdi-arrow-up-bold-box-outline</v-icon
                >
                <span class="font-weight-bold text-lg">
                  {{ $t("cycle.save change") }}
                </span>
              </button>
            </v-col>
            <v-col lg="6" cols="6" class="mx-auto mb-6">
              <v-card class="card-shadow mt-6" id="basic">
                <Header :name="$t('stage.Stages')"></Header>
                <button class="py-5 px-6 mb-0 ms-auto" @click="add_stage()">
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
                <v-form ref="formStage" v-model="valid" lazy-validation>
                  <div class="px-6 pb-6">
                    <v-row
                      class=""
                      v-for="(input, index) in form_data_stage"
                      :key="`dataInput-${index}`"
                    >
                      <v-col lg="4" class="py-0">
                        <!-- <div class="form-controle">
                        Stage {{input.sort}}
                      </div> -->
                        <label
                          class="text-xs text-typo font-weight-bolder ms-1"
                          >{{ $t("cycle.sort") }}</label
                        >
                        <v-text-field
                          type="number"
                          outlined
                          color="rgba(0,0,0,.6)"
                          class="
                            font-size-input
                            placeholder-lighter
                            text-light-input
                          "
                          :rules="[
                            (v) => !!v || this.$i18n.t('form.Item is required'),
                          ]"
                          v-model="input.sort"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col lg="6" class="py-0">
                        <label
                          class="text-xs text-typo font-weight-bolder ms-1"
                          >{{ $t("cycle.stage") }}</label
                        >
                        <!-- {{ typeof input.stage_id }} -->
                        <v-select
                          :items="computedItems"
                          :label="$t('general.choose')"
                          dense
                          outlined
                          :value="input.stage_id"
                          :rules="[
                            (v) => !!v || this.$i18n.t('form.Item is required'),
                          ]"
                          @change="select_stage(input, $event)"
                        ></v-select>
                      </v-col>
                      <v-col lg="2" class="py-0">
                        <v-btn
                          :ripple="false"
                          class="
                            d-inline-block
                            font-weight-bold
                            text-xs
                            bg-gradient-danger
                            px-2
                            mb-0
                            mt-5
                            ms-auto
                          "
                          color="primary"
                          @click="remove_stage(index)"
                        >
                          <v-icon>mdi-minus-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Form from "../Components/Old/Form.vue";
import Notify from "../Components/Old/Notify.vue";
import Loading from "../Components/Loading.vue";
import Header from "../Components/Header.vue";
import CycleService from "../../services/cycle.service";
import StageService from "../../services/stage.service";
export default {
  name: "New-Cycle",
  components: {
    Form,
    Loading,
    Notify,
    Header,
  },
  data() {
    return {
      valid: true,
      show_btn: false,
      loading: true,
      loader_form: false,
      selected: [],
      stages: [],
      message: { msg: null, type: null },
      form_data_stage: [],
      data_style: [
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("cycle.title in english"),
          error: null,
          value_text: "title",
          value: "",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("cycle.title in arabic"),
          error: null,
          value_text: "title_ar",
          value: "",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "textarea",
          row: 7,
          label: this.$i18n.t("cycle.Description in english"),
          error: null,
          value_text: "description",
          value: "",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "textarea",
          row: 7,
          label: this.$i18n.t("cycle.Description in arabic"),
          error: null,
          value_text: "description_ar",
          value: "",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "date",
          label: this.$i18n.t("cycle.Start"),
          error: null,
          value_text: "start",
          value: "",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "date",
          label: this.$i18n.t("cycle.Expire"),
          error: null,
          value_text: "expire",
          value: "",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],
    };
  },
  computed: {
    computedItems() {
      return this.stages.map((item) => {
        return {
          text: item.name_lang,
          value: item.id,
          disabled: this.selected.includes(item.id),
        };
      });
    },
  },
  methods: {
    edit_cycle(valid) {
      this.loader_form = true;
      if (valid) {
        this.show_btn = true;
        this.loader_form = false;
      } else {
        this.loader_form = false;
      }
    },
    add_stage() {
      this.form_data_stage.push({ stage_id: "", sort: "" });
    },
    remove_stage(index) {
      this.form_data_stage.splice(index, 1);
      this.selected.splice(index, 1);
      console.log(this.selected);
    },
    select_stage(item, event) {
      if (this.selected.includes(item.stage_id)) {
        var index = this.selected.indexOf(item.stage_id);
        this.selected.splice(index, 1);
      }
      item.stage_id = event;
      this.selected.push(event);
      console.log(this.selected);
    },

    save_change() {
      if (this.validate()) {
        let formData = new FormData();
        this.data_style.map(function (filter) {
          if (filter.value_text == "start") {
            formData.append("start_edit", filter.value);
          } else {
            formData.append(filter.value_text, filter.value);
          }
        });
        formData.append("id", this.$route.params.id);
        for (let i = 0; i < this.form_data_stage.length; i++) {
          const element = this.form_data_stage[i];
          formData.append("stages[" + i + "][sort]", element.sort);
          formData.append("stages[" + i + "][stage_id]", element.stage_id);
        }
        return CycleService.edit_cycle(this.$route.params.id, formData).then(
          (response) => {
            // console.log(response);
            this.loader_form = false;
            this.$router.push({
              name: "CyclePage",
              params: {
                message: { msg: response.data.message, type: "Success" },
              },
            });
          },
          (error) => {
            console.log(error);
            this.loader_form = false;
            if (error.response.status == 422) {
              var errors = error.response.data.errors;
              for (let i = 0; i < this.data_style.length; i++) {
                const element = this.data_style[i];
                element.error = errors[element.value_text];
              }
            } else {
              this.message = {
                msg: this.$i18n.t("general.there is problem"),
                type: "Danger",
              };
            }
          }
        );
      }
    },

    validate() {
      return this.$refs.formStage.validate();
    },
    get_cycle() {
      return CycleService.get_cycle(this.$route.params.id).then(
        (response) => {
          // console.log(response);
          var cycle = response.data.cycle;
          // this.data.map(function (item) {
          //   item.value = data[item.value_text];
          // });
          this.data_style.map(function (item) {
            if (cycle[item.value_text] != null)
              item.value = cycle[item.value_text];
          });
          for (let i = 0; i < cycle.stages.length; i++) {
            const element = cycle.stages[i];
            this.form_data_stage.push({
              stage_id: parseInt(element.pivot.stage_id),
              sort: element.pivot.sort,
            });
            this.selected.push(parseInt(element.pivot.stage_id));
          }
          this.stages = response.data.stages;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
  },
  mounted() {
    // this.loading = false;
    this.get_cycle();
    console.log(this.form_data_stage);
    document.title = this.$i18n.t("cycle.Edit Cycle");
  },
};
</script>
